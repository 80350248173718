import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'
import { Transition } from 'react-transition-group'

import * as Analytics from '@rushplay/analytics'
import * as Api from '@rushplay/api-client'
import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as Notifications from '@rushplay/notifications'
import styled from '@emotion/styled'

import * as CombinedSelectors from './combined-selectors'
import * as Constants from './constants'
import * as Player from './player'
import * as Icons from './icons'
import { OfferPicker } from './offer-picker'
import { Payer } from './payer'
import { TransactionAmount } from './transaction-amount'
import { useSafeUpdateQuery } from './use-safe-update-query'
import { useTransactionAmountLimits } from './use-transaction-amount-limits'

const duration = 300
const defaultStyle = {
  transition: `${duration}ms ease-in-out`,
  opacity: 0,
}

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
}

const AnimationWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const Wrapper = styled.div`
  flex-grow: 1;
  padding-bottom: 16px;
  height: 100%;

  > form {
    height: 100%;
  }
`

function TransactionConsumer(props) {
  const dispatch = ReactRedux.useDispatch()
  const history = ReactRouter.useHistory()
  const [isMounted, setIsMounted] = React.useState(false)
  const [depositMethod, setDepositMethod] = React.useState('')
  const depositMethodRef = React.useRef(depositMethod)
  const amountCents =
    Forms.useField('#/properties/amount', { noRegister: true }).value || 0
  const closeWalletUrl = useSafeUpdateQuery({ wallet: null })
  const offers = ReactRedux.useSelector(state =>
    CombinedSelectors.getCalculatedOffers(state, { amountCents })
  )

  React.useEffect(() => {
    setIsMounted(true)
  }, [])

  React.useEffect(() => {
    if (offers && offers.length > 0) {
      props.onOfferChange(offers[0].id)
    }
  }, [offers])

  function updateDepositMethod(newState) {
    depositMethodRef.current = newState
    setDepositMethod(newState)
  }

  const onSelectMethod = React.useCallback(
    method => {
      const isDeposit =
        props.transactionType === Constants.TransactionType.DEPOSIT
      updateDepositMethod(method)
      if (isDeposit) {
        dispatch(Analytics.selectPaymentMethod(method))
      }
    },
    [dispatch, props.transactionType]
  )

  const handleSuccess = React.useCallback(() => {
    const isDeposit =
      props.transactionType === Constants.TransactionType.DEPOSIT

    dispatch([
      Api.deleteClaimedDepositOffer({ version: 2 }),
      Notifications.add({
        message: `wallet.transaction-success.${props.transactionType}.content`,
        variables: { amount: amountCents },
        level: 'success',
      }),
    ])
    // hack to avoid fetching player info before deposit count is updated on BE side
    setTimeout(
      () =>
        isDeposit &&
        dispatch([
          Player.fetchPlayerInfo({
            success: () =>
              Analytics.completeTransaction(
                'successful',
                depositMethodRef.current
              ),
          }),
        ]),
      5000
    )
    history.push(`?${closeWalletUrl}`)
  }, [dispatch, props.transactionType, depositMethodRef.current, amountCents])

  const onFailure = React.useCallback(
    status => {
      const isDeposit =
        props.transactionType === Constants.TransactionType.DEPOSIT
      if (isDeposit) {
        dispatch(
          Player.fetchPlayerInfo({
            success: () =>
              Analytics.completeTransaction(status, depositMethodRef.current),
          })
        )
      }
    },
    [dispatch, props.transactionType, depositMethodRef.current]
  )

  const onCancel = React.useCallback(() => {
    onFailure('cancelled')
    props.onStepChange(Constants.TransactionStep.Amount)
  }, [dispatch, props.onStepChange])

  return (
    <>
      {props.step === Constants.TransactionStep.Amount && (
        <Transition
          in={isMounted && props.step === Constants.TransactionStep.Amount}
          timeout={{
            exit: duration,
          }}
          key="amount-step"
          disabled={props.disabled}
        >
          {state => (
            <AnimationWrapper
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
            >
              <TransactionAmount
                offers={offers}
                selectedOfferId={props.offerId}
                transactionType={props.transactionType}
                disabled={props.disabled}
                offerPicker={
                  <OfferPicker
                    selectedOfferId={props.offerId}
                    transactionType={props.transactionType}
                    onChangeOfferId={props.onOfferChange}
                  />
                }
              />
            </AnimationWrapper>
          )}
        </Transition>
      )}

      {isMounted && props.step === Constants.TransactionStep.Payer && (
        <Transition
          in={props.step === Constants.TransactionStep.Payer}
          timeout={{
            exit: duration,
          }}
          key="payer-step"
        >
          {state => (
            <AnimationWrapper
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
            >
              <Payer
                transactionType={props.transactionType}
                amountCents={amountCents}
                offerId={props.offerId}
                onSelectMethod={onSelectMethod}
                onFailure={onFailure}
                onSuccess={handleSuccess}
                onCancel={onCancel}
              />
            </AnimationWrapper>
          )}
        </Transition>
      )}
    </>
  )
}

TransactionConsumer.propTypes = {
  offerId: PropTypes.string,
  step: PropTypes.number.isRequired,
  transactionType: PropTypes.oneOf([
    Constants.TransactionType.AUTH,
    Constants.TransactionType.DEPOSIT,
    Constants.TransactionType.WITHDRAWAL,
  ]).isRequired,
  onOfferChange: PropTypes.func.isRequired,
  onStepChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}

// Provider
export function Transaction(props) {
  const dispatch = ReactRedux.useDispatch()
  const [offerId, setOfferId] = React.useState()

  const { limits, loading } = useTransactionAmountLimits(props.transactionType)

  const leftToDepositCents = ReactRedux.useSelector(state =>
    Player.getDepositLimitRemainder(state.player)
  )
  const playerBalanceCents = ReactRedux.useSelector(state =>
    Player.getMoneyBalanceCents(state.player)
  )
  const playerWithdrawLimits = ReactRedux.useSelector(state =>
    Player.getWithdrawLimits(state.player)
  )

  const playerDailyLimit =
    playerWithdrawLimits.find(limit => limit.interval === 'day') || {}

  const leftToWithdrawCents = playerDailyLimit.limitCents
    ? Math.min(playerBalanceCents, playerDailyLimit.leftToWithdrawCents)
    : playerBalanceCents

  const playerLimitMaximum =
    props.transactionType === Constants.TransactionType.DEPOSIT
      ? leftToDepositCents
      : leftToWithdrawCents

  const defaultLimits = Constants.DEFAULT_LIMITS_MAPPING[props.transactionType]

  const dataSchema = React.useMemo(
    () =>
      !loading && {
        properties: {
          amount: {
            type: 'number',
            maximum: R.min(
              limits?.max ?? defaultLimits?.max,
              playerLimitMaximum
            ),
            minimum: limits?.min ?? defaultLimits?.min,
          },
        },
        required: ['amount'],
        type: 'object',
      },
    [limits, loading, playerLimitMaximum]
  )

  if (loading) {
    return (
      <Common.Box fontSize={6} pt={2} display="flex" justifyContent="center">
        <Icons.Spinner />
      </Common.Box>
    )
  }

  return (
    <Wrapper key={props.transactionType}>
      <Forms.Provider
        schema={dataSchema}
        name="transaction"
        onSubmit={(errors, data) => {
          if (R.isEmpty(errors)) {
            if (offerId) {
              dispatch(
                Api.claimDepositOffer(offerId, {
                  failure: res =>
                    Notifications.add({
                      message: `errors.${res.value.message ||
                        'general.unknown'}`,
                      level: 'error',
                    }),
                  version: 2,
                })
              )
            }

            if (props.transactionType === Constants.TransactionType.DEPOSIT) {
              dispatch(
                Player.fetchPlayerInfo({
                  success: () => Analytics.selectAmount(data.amount),
                })
              )
            }

            return props.onStepChange(Constants.TransactionStep.Payer)
          }
        }}
      >
        <TransactionConsumer
          offerId={offerId}
          step={props.step}
          transactionType={props.transactionType}
          onOfferChange={setOfferId}
          onStepChange={props.onStepChange}
          disabled={playerLimitMaximum === 0}
        />
      </Forms.Provider>
    </Wrapper>
  )
}

Transaction.propTypes = {
  step: PropTypes.number,
  transactionType: PropTypes.oneOf([
    Constants.TransactionType.AUTH,
    Constants.TransactionType.DEPOSIT,
    Constants.TransactionType.WITHDRAWAL,
  ]),
  onStepChange: PropTypes.func.isRequired,
}
