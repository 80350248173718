import * as React from 'react'
import * as ReactRedux from 'react-redux'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'

import * as Inventory from './inventory'
import * as Player from './player'
import { Toast } from './toast'

export function WithdrawalLimitInfo() {
  const i18n = I18n.useI18n()
  const dispatch = ReactRedux.useDispatch()

  const withdrawalInfo = ReactRedux.useSelector(state =>
    Player.getWithdrawalInformation(state.player)
  )
  const withdrawLimits = ReactRedux.useSelector(state =>
    Player.getWithdrawLimits(state.player)
  )
  const dateNow = Date.now()

  const inventoryItems = ReactRedux.useSelector(state =>
    Inventory.getActiveItems(state.inventory, { dateNow })
  )

  const dailyWithdrawLimit =
    withdrawLimits.find(limit => limit.interval === 'day') || {}

  function onFetch() {
    dispatch(Player.fetchWithdrawInformation())
  }

  React.useEffect(() => {
    onFetch()
  }, [])

  if (
    (!inventoryItems || inventoryItems.length === 0) &&
    (!withdrawalInfo.bonusBalanceCents ||
      withdrawalInfo.bonusBalanceCents === 0) &&
    !dailyWithdrawLimit.limitCents
  ) {
    return null
  }

  return (
    <Common.Box pb={1}>
      {((inventoryItems && inventoryItems.length > 0) ||
        withdrawalInfo.bonusBalanceCents > 0) && (
        <Toast title={i18n.translate('toast.warning')} variant="warning">
          {i18n.translate('wallet.withdrawl.bonus-disclaimer', {
            bonusAmount: withdrawalInfo.bonusBalanceCents,
            moneyAmount: withdrawalInfo.moneyBalanceCents,
            wagerBalance: withdrawalInfo.wagerBalanceCents,
          })}
        </Toast>
      )}
      {dailyWithdrawLimit.limitCents && (
        <Common.Box pt={1}>
          <Toast title={i18n.translate('toast.warning')} variant="warning">
            {i18n.translate('wallet.withdrawal.daily-withdraw-limit', {
              limitCents: dailyWithdrawLimit.limitCents,
              leftToWithdrawCents: dailyWithdrawLimit.leftToWithdrawCents,
            })}
          </Toast>
        </Common.Box>
      )}
    </Common.Box>
  )
}
