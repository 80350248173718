import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'

import * as Constants from './constants'
import * as Player from './player'
import * as Theming from './theming'
import { DepositCampaignOfferPicker } from './deposit-campaign-offer-picker'
import { DepositLimitInformation } from './deposit-limit-info'
import { InputField } from './input-field'
import { PendingTransactions } from './pending-transactions'
import { SubmitButton } from './submit-button'
import { WithdrawalLimitInfo } from './withdrawal-limit-info'
import { getSearchQuery } from './get-search-query'

function normalizeAmount(value) {
  const amount = parseInt(value, 10)
  if (Number.isNaN(amount)) {
    return 0
  }
  return Math.round(amount * 100)
}

function parseAmount(value) {
  const amount = Math.round(value / 100)
  if (Number.isNaN(amount)) {
    return ''
  }

  return amount
}

export function TransactionAmount(props) {
  const i18n = I18n.useI18n()
  const location = ReactRouter.useLocation()
  const query = getSearchQuery(location)
  const currency = Player.useCurrency()
  const minDepositLimit = Constants.DEFAULT_LIMITS_MAPPING.deposit.min
  const scope = '#/properties/amount'
  const isDeposit = props.transactionType === Constants.TransactionType.DEPOSIT
  const lastDepositAmount = ReactRedux.useSelector(state =>
    Player.getLastDepositAmount(state.player)
  )
  const firstDeposit = ReactRedux.useSelector(state =>
    Player.getInitialDeposit(state.player)
  )

  // If WO exists - set min required deposit for reward as default value for deposit
  //TODO: solve the failed fetching that lead to undef value on firstDeposit

  const defaultDepositValue =
    props.offers && props.offers.length > 0
      ? props.offers[0].minDepositForFullRewards || minDepositLimit
      : // this hardcoded fallback is a quick fix for occasions when fail to fetch offers and player
        lastDepositAmount || firstDeposit || 30000
  const initialValue = isDeposit ? defaultDepositValue : null

  return (
    <>
      <PendingTransactions key="pending-transactions" />
      <Theming.Primary>
        <InputField
          disabled={props.disabled}
          currency={currency}
          inputMode="decimal"
          parse={parseAmount}
          normalize={normalizeAmount}
          initialValue={query.amount || initialValue}
          scope={scope}
          data-testid={`wallet.${props.transactionType}.amount`}
        />
      </Theming.Primary>
      <Common.Box pt={2} pb={4} display="flex" justifyContent="center">
        <SubmitButton
          onlyScopes={[scope]}
          data-testid={`wallet.${props.transactionType}.submit`}
        >
          {i18n.translate(`transaction.${props.transactionType}.amount.submit`)}
        </SubmitButton>
      </Common.Box>
      {props.offerPicker}
      {isDeposit && <DepositCampaignOfferPicker />}
      {isDeposit ? <DepositLimitInformation /> : <WithdrawalLimitInfo />}
    </>
  )
}

TransactionAmount.propTypes = {
  offerPicker: PropTypes.node,
  transactionType: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  offers: PropTypes.array,
}

// for @loadable/components
export default TransactionAmount
