import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as Url from 'url'
import PropTypes from 'prop-types'

import * as Analytics from '@rushplay/analytics'
import * as Common from '@rushplay/common'
import * as Jurisdiction from '@rushplay/compliance/jurisdiction'

import * as Configuration from './configuration'
import * as Icons from './icons'
import * as Player from './player'
import * as ServerConfiguration from './server-configuration'
import * as Session from './session'

function getPayerConfig(state) {
  return {
    clientType: Configuration.getClientType(state.configuration),
    countryCode: Player.getCountryCode(state.player),
    license: Jurisdiction.getLicense(state.jurisdiction),
    host: Configuration.getPayerUrl(state.configuration),
    token: Session.getToken(state.session),
    userId: Player.getUsername(state.player),
  }
}

function getInitialData(state) {
  return {
    affiliateClickId: Analytics.getClickId(state.analytics),
    email: Player.getEmail(state.player),
    firstName: Player.getFirstName(state.player),
    lastName: Player.getLastName(state.player),
    netrefererBtag: Analytics.getBtag(state.analytics),
    phoneNumber: Player.getPhoneNumber(state.player),
    utmCampaign: Analytics.getUtmCampaign(state.analytics),
    utmMedium: Analytics.getUtmMedium(state.analytics),
    utmSource: Analytics.getUtmSource(state.analytics),
  }
}

export function Payer(props) {
  const ref = React.useRef(null)
  const [loading, setLoading] = React.useState(true)
  const serverConfig = ServerConfiguration.useContext()
  const config = ReactRedux.useSelector(getPayerConfig)
  const initialData = ReactRedux.useSelector(getInitialData)

  const payerUrl = Url.format({
    pathname: props.transactionType,
    query: {
      amount_cents: props.amountCents,
      client_type: config.clientType,
      // Always use player country-code if availablae
      country_code: config.countryCode || serverConfig.country.alpha2,
      currency: serverConfig.currency.code,
      language: serverConfig.locale.language,
      offer_id: props.offerId,
      token: config.token,
      user_id: config.userId,
    },
  })

  React.useEffect(() => {
    function payerEventListener(event) {
      const action = event.data || {}
      switch (action.type) {
        case 'payer/INITIATED': {
          // Sets up tracking & Prefill forms with initial data
          ref.current.contentWindow.postMessage(
            { type: 'payer/UPDATE_INFORMATION', payload: initialData },
            '*'
          )
          break
        }

        case 'payer/STEP_CHANGED': {
          props.onStepChange && props.onStepChange(action.payload)
          break
        }

        case 'payer/TRANSACTION_CANCELED': {
          props.onCancel && props.onCancel(action.payload)
          break
        }

        case 'payer/PAYMENT_METHOD_SELECTED': {
          props.onSelectMethod && props.onSelectMethod(action.payload)
          break
        }

        case 'payer/TRANSACTION_FAILED': {
          props.onFailure && props.onFailure('failed', action.payload)
          break
        }

        case 'payer/TRANSACTION_SUCCEEDED': {
          props.onSuccess && props.onSuccess(action.payload)
          break
        }

        default: {
          return null
        }
      }
    }
    window.addEventListener('message', payerEventListener)
    return () => window.removeEventListener('message', payerEventListener)
  }, [])

  return (
    <React.Fragment>
      {loading && (
        <Common.Box fontSize={6} pt={2} display="flex" justifyContent="center">
          <Icons.Spinner />
        </Common.Box>
      )}
      <Common.Box
        as="iframe"
        width="100%"
        height={loading ? '0%' : '100%'}
        opacity={loading ? '0' : '1'}
        transition="opacity 300ms ease"
        borderRadius={1}
        sandbox={R.join(' ', [
          'allow-forms',
          'allow-modals',
          'allow-orientation-lock',
          'allow-popups',
          'allow-popups-to-escape-sandbox',
          'allow-presentation',
          'allow-same-origin',
          'allow-scripts',
        ])}
        src={`${config.host}/${payerUrl}`}
        onLoad={() => setLoading(false)}
        ref={ref}
        title="Wallet"
      />
    </React.Fragment>
  )
}

Payer.propTypes = {
  amountCents: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  offerId: PropTypes.string,
  transactionType: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  onFailure: PropTypes.func,
  onStepChange: PropTypes.func,
  onSuccess: PropTypes.func,
  onSelectMethod: PropTypes.func,
}
