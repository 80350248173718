import * as EmotionTheming from 'emotion-theming'
import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as I18n from '@rushplay/i18n'
import * as Offers from '@rushplay/offers'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Constants from './constants'
import * as CombinedSelectors from './combined-selectors'
import * as Icons from './icons'
import * as Utils from './utils'
import { Button } from './button'
import { CircularProgressbar } from './circular-progressbar'
import { HtmlContent } from './html-content'

const RewardListWrapper = styled.li`
  ${props =>
    css({
      fontSize: 4,
      color: props.disabled && 'text-disabled',
    })}

  & b, & strong {
    ${css({
      color: 'text-highlight',
    })}
  }
`

function Picker(props) {
  const i18n = I18n.useI18n()
  const theme = EmotionTheming.useTheme()

  // deselect offer if not eligable
  React.useEffect(() => {
    if (props.disabled) {
      props.onChange(false)
    }
  }, [props.disabled])

  const selected = props.selectedOfferId === props.id

  return (
    <Common.Box
      backgroundColor="g-bg"
      backgroundImage={theme['card-gradient']}
      borderRadius={0}
      p={1}
      color="g-text"
      boxShadow={selected ? 'inset 0 0 1px 6px #009f66' : 'none'}
      style={{
        transition: 'box-shadow 150ms ease-in-out',
      }}
    >
      <Common.Flex>
        <Common.Box flexShrink="0">
          <CircularProgressbar
            disabled={props.disabled || (props.selectedOfferId && !selected)}
            id={props.id}
            backgroundUrl={i18n.translate(`offer-picker.${props.id}.img-src`)}
            percent={props.progress}
          />
        </Common.Box>
        <Common.Box
          px={1}
          flexGrow="1"
          flexShrink="1"
          color={props.disabled ? 'text-disabled' : 'g-text'}
        >
          <Common.Box
            fontFamily="head"
            fontWeight="bold"
            fontSize={2}
            mb={1}
            style={{
              whiteSpace: 'pre-wrap',
              textTransform: 'uppercase',
              letterSpacing: '0.05em',
            }}
          >
            {i18n.translate(
              `offer-picker.${props.id}.title`,
              props.translationVariables
            )}
          </Common.Box>
          <Common.Box
            as="ul"
            display="grid"
            gridGap={0}
            ml={1}
            py={0}
            style={{ listStyle: 'disc' }}
          >
            {props.rewards.map(
              reward =>
                reward.translationVariables && (
                  <RewardListWrapper
                    key={reward.key}
                    disabled={!props.disabled && !reward.value}
                  >
                    <HtmlContent
                      html={{
                        __html: i18n.translate(
                          reward.key,
                          reward.translationVariables
                        ),
                      }}
                    />
                  </RewardListWrapper>
                )
            )}
          </Common.Box>
          {!props.disabled && props.depositLeftForFullRewards > 0 && (
            <Common.Box
              fontSize={2}
              pt={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {i18n.translate('offer-picker.entice-greater-deposit', {
                depositLeftForFullRewardsCents: props.depositLeftForFullRewards,
              })}
            </Common.Box>
          )}
          {props.disabled && (
            <Common.Box
              fontSize={2}
              color="danger"
              pt={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Common.Box pr="4px" fontSize={2} display="inline-flex">
                <Icons.Error />
              </Common.Box>
              {i18n.translate('offer-picker.minimum-deposit-amount', {
                minDepositAmount: props.translationVariables.minDepositAmount,
              })}
            </Common.Box>
          )}
          <Common.Box display="flex" my={1}>
            <Button
              disabled={props.disabled}
              type="button"
              variant={selected ? 'secondary' : 'secondary-outlined'}
              onClick={() => props.onChange(props.id)}
            >
              {i18n.translate(
                selected ? 'offer-picker.deselect' : 'offer-picker.claim'
              )}
            </Button>
          </Common.Box>
          <Common.Text fontSize={1} textDecoration="underline">
            <ReactRouter.Link to="/terms-and-conditions/welcome-offer">
              {i18n.translate('offer-picker.terms-and-conditions.link')}
            </ReactRouter.Link>
          </Common.Text>
        </Common.Box>
      </Common.Flex>
    </Common.Box>
  )
}

Picker.propTypes = {
  disabled: PropTypes.bool,
  depositLeftForFullRewards: PropTypes.number,
  id: PropTypes.number,
  progress: PropTypes.number,
  rewards: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.number,
      translationVariables: PropTypes.object,
    })
  ),
  selectedOfferId: PropTypes.number,
  translationVariables: PropTypes.object,
  onChange: PropTypes.func,
}

export function OfferPicker(props) {
  const i18n = I18n.useI18n()
  const amountField = Forms.useField('#/properties/amount', {
    noRegister: true,
  })
  const amountCents = amountField.value ? amountField.value : 0
  const isDeposit = props.transactionType === Constants.TransactionType.DEPOSIT

  const offers = ReactRedux.useSelector(state =>
    CombinedSelectors.getCalculatedOffers(state, { amountCents })
  )

  function handleChange(offerId) {
    if (offerId && R.not(R.equals(offerId, props.selectedOfferId))) {
      props.onChangeOfferId(offerId)
    } else {
      props.onChangeOfferId(null)
    }
  }

  if (R.isEmpty(offers) || R.not(isDeposit)) {
    return null
  }

  return (
    <Common.Space pt={0}>
      <Common.Text
        fontSize={3}
        fontWeight="bold"
        style={{ textTransform: 'uppercase', letterSpacing: '0.05em' }}
      >
        {i18n.translate('offer-picker.select-offer')}
      </Common.Text>
      <Common.Space py={1}>
        {offers.map(offer => (
          <Common.Space key={offer.id} py={0}>
            <Picker
              disabled={amountCents < R.head(offer.rangeLimits)}
              selectedOfferId={props.selectedOfferId}
              progress={offer.offerProgressPercent}
              depositLeftForFullRewards={
                offer.minDepositForFullRewards - amountCents
              }
              rewards={Offers.getOfferRewards(Utils.toArray(offer))}
              translationVariables={R.mergeAll([
                { minDepositAmount: R.head(offer.rangeLimits) },
                Offers.getMaxRewardsTranslationVariables(offers),
              ])}
              id={offer.id}
              onChange={handleChange}
            />
          </Common.Space>
        ))}
      </Common.Space>
    </Common.Space>
  )
}

OfferPicker.propTypes = {
  selectedOfferId: PropTypes.number,
  transactionType: PropTypes.string.isRequired,
  onChangeOfferId: PropTypes.func,
}

// for @loadable/components
export default OfferPicker
