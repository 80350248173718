import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as I18n from '@rushplay/i18n'

import * as Theming from './theming'
import * as Icons from './icons'
import * as Promotions from './promotions'
import { CircularProgressbar } from './circular-progressbar'
import { Divider } from './divider'
import { HtmlContent } from './html-content'
import { mapIndexed } from './map-indexed'

export function DepositCampaignOfferPicker() {
  const i18n = I18n.useI18n()
  const campaignOffers = ReactRedux.useSelector(state =>
    Promotions.getOptedInDepositCampaigns(state.promotions, {
      dateNow: Date.now(),
    })
  )

  const { value: amountCents } = Forms.useField('#/properties/amount', {
    noRegister: true,
  })

  if (R.isEmpty(campaignOffers)) {
    return null
  }

  return (
    <Common.Box display="grid" gridGap={1} pt={2}>
      <Common.Box fontSize={3} textAlign="center" pb={0}>
        {i18n.translate('promotions.bonuses-on-deposit')}
      </Common.Box>
      {Object.keys(campaignOffers).map(promotion => {
        const rewards = R.pathOr(
          [],
          [promotion, 'campaign', 'rewards'],
          campaignOffers
        )

        return (
          <CampaignOffer
            amountCents={amountCents}
            promotionId={promotion}
            rewards={rewards}
            key={R.path([promotion, 'campaign', 'id'], campaignOffers)}
          />
        )
      })}
    </Common.Box>
  )
}

function CampaignOffer(props) {
  const i18n = I18n.useI18n()

  const promotionMinMax = Promotions.getPromotionMinMax(props.rewards)

  const combinedProgress =
    props.amountCents / promotionMinMax.minDepositForFullPackageCents > 1
      ? 100
      : (props.amountCents / promotionMinMax.minDepositForFullPackageCents) *
        100

  const disabled = props.amountCents < promotionMinMax.minDepositForAnyReward

  const leftToDepositForFullRewardsCents =
    promotionMinMax.minDepositForFullPackageCents - props.amountCents

  return (
    <Theming.Alternative>
      <Common.Box
        backgroundColor="g-bg"
        borderRadius={0}
        p={1}
        color={disabled ? 'text-disabled' : 'g-text'}
        display="grid"
      >
        <Common.Box display="flex" alignItems="center">
          <Common.Box flexShrink="0">
            <CircularProgressbar
              disabled={disabled}
              id={props.promotionId}
              backgroundUrl={i18n.translate(
                `promotions.${props.promotionId}.img-src`
              )}
              percent={R.defaultTo(0, combinedProgress)}
            />
          </Common.Box>
          <Common.Box fontSize={2} pl={1} flexGrow="1">
            <Common.Box fontWeight="bold" style={{ whiteSpace: 'pre-wrap' }}>
              {i18n.translate(
                `promotions.${props.promotionId}.title`,
                promotionMinMax
              )}
            </Common.Box>
            <Common.Box
              display="grid"
              gridGap="4px"
              pt={0}
              as="ul"
              ml="1.25em"
              style={{ listStyle: 'disc' }}
            >
              {mapIndexed(
                (reward, index) => (
                  <Common.Box
                    color={
                      Promotions.isRewardDisabled(reward, props.amountCents) &&
                      'text-disabled'
                    }
                    fontSize={1}
                    as="li"
                    key={index}
                  >
                    <HtmlContent
                      html={{
                        __html: i18n.translate(
                          Promotions.getRewardTranslationKey(reward),
                          Promotions.getRewardTranslationVariables(
                            reward,
                            props.amountCents
                          )
                        ),
                      }}
                    />
                  </Common.Box>
                ),
                props.rewards
              )}
            </Common.Box>
          </Common.Box>
        </Common.Box>
        {i18n.hasTranslation(
          `promotions.${props.promotionId}.terms-conditions`
        ) && (
          <React.Fragment>
            <Common.Box pt={1} pb={1} color="g-text">
              <Divider />
            </Common.Box>
            <Common.Text fontSize={1} textDecoration="underline" color="g-text">
              <ReactRouter.Link to={`/promotions/${props.promotionId}`}>
                {i18n.translate('promotions.terms-and-conditions')}
              </ReactRouter.Link>
            </Common.Text>
          </React.Fragment>
        )}
        {!disabled && leftToDepositForFullRewardsCents > 0 && (
          <Common.Box
            fontSize={1}
            color="info"
            pt={1}
            fontStyle="italic"
            display="flex"
            justifyContent="center"
            alignItems="center"
            lineHeight="1.25"
          >
            <Common.Box pr="4px" fontSize={2} display="inline-flex">
              <Icons.Info />
            </Common.Box>
            {i18n.translate('offer-picker.entice-greater-deposit', {
              depositLeftForFullRewardsCents: leftToDepositForFullRewardsCents,
            })}
          </Common.Box>
        )}
        {disabled && (
          <Common.Box
            lineHeight="1.25"
            fontSize={1}
            color="danger"
            pt={1}
            fontStyle="italic"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Common.Box pr="4px" fontSize={2} display="inline-flex">
              <Icons.Error />
            </Common.Box>
            {i18n.translate('offer-picker.minimum-deposit-amount', {
              minDepositAmount: promotionMinMax.minDepositForAnyReward,
            })}
          </Common.Box>
        )}
      </Common.Box>
    </Theming.Alternative>
  )
}

CampaignOffer.propTypes = {
  allRewardsMinimumDeposit: PropTypes.number,
  amountCents: PropTypes.number,
  promotionId: PropTypes.string,
  rewards: PropTypes.array,
}
