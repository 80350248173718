import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as Url from 'url'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import css from '@styled-system/css'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

import * as Configuration from './configuration'
import * as Session from './session'
import * as Player from './player'
import { Divider } from './divider'
import { PendingTransaction } from './pending-transaction'

const pendingTransactionAnimation = keyframes`
  0% {
    transform: translateX(10px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
   opacity: 1;
  }
`

const Transition = styled.div`
  opacity: 0;
  transform: translateX(20px);

  &.animated {
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-name: ${pendingTransactionAnimation};
    ${props =>
      css({
        animationDelay: `${props.delay}s`,
      })};
  }
`

function getPayerConfig(state) {
  return {
    host: Configuration.getPayerUrl(state.configuration),
    token: Session.getToken(state.session),
    userId: Player.getUsername(state.player),
  }
}

function usePendingTransactions() {
  const [pendingTransactions, setPendingTransactions] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [deleting, setDeleting] = React.useState(false)
  const config = ReactRedux.useSelector(getPayerConfig)
  const dispatch = ReactRedux.useDispatch()

  const pendingTransactionsUrl = Url.format({
    pathname: '/api/pending-transactions',
    query: {
      token: config.token,
      user_id: config.userId,
    },
  })

  const onCancel = React.useCallback(
    (id, onErrorCallback) => {
      const apiUrl = Url.format({
        pathname: `/api/pending-transactions/${id}`,
        query: {
          token: config.token,
          user_id: config.userId,
        },
      })

      setDeleting(true)

      fetch(`${config.host}${apiUrl}`, { method: 'DELETE' })
        .then(() => {
          setDeleting(false)
          return dispatch(Player.fetchWithdrawInformation())
        })
        .catch(() => {
          setDeleting(false)
          onErrorCallback(false)
        })
    },
    [pendingTransactions, config.host, config.token, config.userId]
  )

  React.useEffect(() => {
    // If there's no userId or token there's no point in fetching
    if (config.userId || config.token) {
      if (!deleting) {
        const controller = new AbortController()
        fetch(`${config.host}${pendingTransactionsUrl}`, { method: 'GET' })
          .then(res => res.json())
          .then(body => {
            const data = body.data.map(item => ({
              ...item,
              onCancel,
            }))
            setPendingTransactions(data)
            setLoading(false)
          })
          .catch(() => {
            setLoading(false)
          })

        const id = setTimeout(() => controller.abort(), 5000)

        return () => {
          clearTimeout(id)
          controller.abort()
        }
      }
    }
  }, [deleting])

  return { pendingTransactions, loading }
}

export function PendingTransactions() {
  const i18n = I18n.useI18n()
  const { pendingTransactions, loading } = usePendingTransactions()
  const [isMounted, setMounted] = React.useState(false)

  React.useEffect(() => {
    setMounted(true)

    return () => setMounted(false)
  }, [])

  return (
    <Common.Space pb={0}>
      {pendingTransactions && pendingTransactions.length > 0 && !loading && (
        <Common.Box
          pb={2}
          fontSize={3}
          fontWeight="bold"
          textAlign="center"
          fontFamily="head"
        >
          {i18n.translate('pending-withdrawals')}
        </Common.Box>
      )}
      {pendingTransactions.map((transaction, index) => (
        <Transition
          className={isMounted ? 'animated' : ''}
          delay={index * 0.04}
          key={transaction.transactionId}
        >
          <PendingTransaction
            amount={transaction.amount}
            date={transaction.created}
            provider={transaction.txType}
            onCancel={onErrorCallback =>
              transaction.onCancel(transaction.transactionId, onErrorCallback)
            }
          />
          <Common.Space py="12px">
            <Divider />
          </Common.Space>
        </Transition>
      ))}
    </Common.Space>
  )
}
