import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as common from '@rushplay/common'
import { useI18n } from '@rushplay/i18n'

import * as ServerConfiguration from './server-configuration'
import { Amount } from './amount'
import { Button } from './button'

export function PendingTransaction(props) {
  const i18n = useI18n()
  const { locale } = ServerConfiguration.useContext()
  const [cancelling, setCancelling] = React.useState(false)
  /**
   * Safari doesn't see date-strings with dashes (-) as a proper date-string hence
   * the replace with slash (/). See this link for more info:
   * https://stackoverflow.com/questions/4310953/invalid-date-in-safari
   */
  const date = new Date(R.replace(/-/g, '/', props.date)).toLocaleDateString(
    locale.slug
  )
  const currency = R.last(R.split(' ', props.amount))
  const amount = parseFloat(R.head(R.split(' ', props.amount)))
  const capitalizedTxType = R.concat(
    props.transactionType.charAt(0).toUpperCase(),
    R.tail(props.transactionType)
  )
  const provider = R.replace(capitalizedTxType, '', props.provider)

  function handleCancelling() {
    setCancelling(true)
    props.onCancel(setCancelling)
  }

  return (
    <common.Box display="flex" justifyContent="space-between" fontSize={2}>
      <common.Flex flexDirection="column" justifyContent="space-between">
        <common.Text fontWeight="bold">{provider}</common.Text>
        <common.Box opacity="0.4">{date}</common.Box>
      </common.Flex>
      <common.Box maxWidth="40%" marginY="auto">
        <Button
          disabled={cancelling}
          onClick={handleCancelling}
          fontSize={0}
          type="button"
        >
          <span>{i18n.translate('reclaim')} </span>
          <Amount decimals="show" currency={currency}>
            {Math.abs(amount) * 100}
          </Amount>
        </Button>
      </common.Box>
    </common.Box>
  )
}

PendingTransaction.defaultProps = {
  amount: '0 EUR',
  date: '0',
  provider: '',
  transactionType: 'withdrawal',
}

PendingTransaction.propTypes = {
  amount: PropTypes.string,
  cancelling: PropTypes.bool,
  date: PropTypes.string,
  provider: PropTypes.string,
  transactionType: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
}
